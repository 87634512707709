import * as React from 'react';
import { Typography, TextField, Grid, MenuItem, FormControlLabel, Checkbox } from '@mui/material';

import { streets } from '../../services/helper';

export default function AdressForm({ endereco, setEndereco }) {

    const [checked, setChecked] = React.useState(false);
    const [checkedExterno, setCheckedExterno] = React.useState(false);

    const ufs = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'];
    
    React.useEffect(() => {
        const local = ['Olivêdos', 'olivedos', 'Olivedos']
        
        setChecked(endereco.numero === 0);
        setCheckedExterno(!local.includes(endereco.cidade));

        if (local.includes(endereco.cidade) && !streets.includes(endereco.rua) && endereco.rua !== '') {
            setEndereco({ ...endereco, rua: '' })
        }
    }, [endereco, setEndereco])

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom mt={2}>
                Dados do Endereço
            </Typography>

            {/* <FormControlLabel sx={{padding: 1}}
                control={<Checkbox value="remember" color="primary" checked={checkedExterno} onChange={e => {
                    setCheckedExterno(e.target.checked);
                    setEndereco({ ...endereco, rua: '' })
                    if (e.target.checked) {
                        setEndereco({ ...endereco, cidade: '' })
                    }
                }} />}
                label="Endereço externo?"
            /> */}

            <Grid container spacing={3}>
                {checkedExterno && <Grid item xs={12}>
                    <TextField
                        required
                        id="rua"
                        name="rua"
                        label="Rua"
                        fullWidth
                        value={endereco.rua}
                        onChange={e => setEndereco({ ...endereco, rua: e.target.value })}
                    />
                </Grid>}
                {!checkedExterno && <Grid item xs={12}>
                    <TextField
                        required
                        id="rua"
                        name="rua"
                        label="Rua"
                        fullWidth
                        select
                        value={endereco.rua}
                        onChange={e => setEndereco({ ...endereco, rua: e.target.value })}
                    >
                        {streets.map(s => (
                            <MenuItem value={s} key={s}>
                                {s}
                            </MenuItem>    
                        ))}
                    </TextField>
                </Grid>}
                <Grid item xs={12}>
                    <TextField
                        id="complemento"
                        name="complemento"
                        label="Complemento"
                        fullWidth
                        value={endereco.complemento}
                        onChange={e => setEndereco({ ...endereco, complemento: e.target.value })}
                    />
                </Grid>
            </Grid>

            <FormControlLabel sx={{padding: 1}}
                control={<Checkbox value="remember" color="primary" checked={checked} onChange={e => {
                    setChecked(e.target.checked);
                    if (e.target.checked) {
                        setEndereco({ ...endereco, numero: 0 })
                    }
                }} />}
                label="Imóvel sem número?"
                id='imovel_sem_numero'
                name='imovel_sem_numero'
            />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={checked}
                        required
                        id="numero"
                        name="numero"
                        label="Número"
                        type="number"
                        min={0}
                        fullWidth
                        value={endereco.numero}
                        onChange={e => {
                            if (e.target.value >= 0)
                                setEndereco({ ...endereco, numero: e.target.value })
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="bairro"
                        name="bairro"
                        label="Bairro"
                        fullWidth
                        value={endereco.bairro}
                        onChange={e => setEndereco({ ...endereco, bairro: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="cidade"
                        name="cidade"
                        label="Cidade"
                        fullWidth
                        value={endereco.cidade}
                        onChange={e => setEndereco({ ...endereco, cidade: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="uf"
                        select
                        fullWidth
                        required
                        label="Estado"
                        value={endereco.estado}
                        onChange={e => setEndereco({ ...endereco, estado: e.target.value })}
                    >
                        {ufs.map(uf => (
                            <MenuItem value={uf} key={uf}>
                                {uf}
                            </MenuItem>    
                        ))}
                    </TextField>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}