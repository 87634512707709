export const clearAlert = () => {
    localStorage.removeItem('AEC_OLIVEDOS_MSG');
    localStorage.removeItem('AEC_OLIVEDOS_MSG_TYPE');
};

export const setAlert = (msg, type='error') => {
    localStorage.setItem('AEC_OLIVEDOS_MSG', msg);
    localStorage.setItem('AEC_OLIVEDOS_MSG_TYPE', type);
    window.dispatchEvent(new Event("storage"));
};

export const getAlert = () => {
    const msg = localStorage.getItem('AEC_OLIVEDOS_MSG');
    const type = localStorage.getItem('AEC_OLIVEDOS_MSG_TYPE');

    return ({
        msg,
        type
    });
};

export const streets = [
    'ANANIAS DOS ANJOS',
    'ANTONIO FRANCISCO DA COSTA',
    'CREUZA CAVALCANTE DE ALMEIDA',
    'DEUSDETH DE SOUZA LIMA',
    'DIVONE MARIA DE OLIVEIRA',
    'ELIANE BARROS DA SILVA',
    'EUTAZIO IMPERIANO',
    'FAUSTO BATISTA GUIMARAES',
    'FRANCISCO ALVES DE SOUZA',
    'FRANCISCO APOLÔNIO',
    'FRANCISCO BATISTA DE SOUSA',
    'FRANCISCO TAVARES MACIEL',
    'FREI IBIAPINA',
    'GEORGIA DE SOUSA BRITO',
    'INACIO CORDEIRO',
    'INACIO EVANGELISTA DA COSTA',
    'JAIME CAVALCANTE DE ALMEIDA',
    'JOAQUIM VERISSIMO DE SOUZA JUNIOR',
    'JOÃO BORGES',
    'JOSÉ ADAUTO DE SOUSA',
    'JOSÉ DEOCLECIANO DE ALMEIDA',
    'JOSÉ FAUSTINO DA COSTA',
    'JOSÉ GONÇALVES',
    'JOSE LIMEIRA DA SILVA',
    'JOSE MARIA FILHO',
    'JOSE SILVINO DIAS',
    'JULIO FRANCELINO DE LIMA',
    'LARGO TEODOSIO DE OLIVEIRA LEDO',
    'LOTEAMENTO JOSÉ ROCHA CAVALCANTE',
    'LOURIVAL ANGELO',
    'LUIZ IMPERIANO',
    'LUZIA DE OLIVEIRA SOUTO',
    'LUZIA FRANCISCA DAS MERCÊS',
    'MAELSON BORGES DE OLIVEIRA',
    'MANOEL PEREIRA DE ARAUJO',
    'MARIA JOSE COSTA DE ALBUQUERQUE',
    'MIGUEL GONÇALVES DA COSTA',
    'ORLANDO DE SOUSA BRITO',
    'OSCAR COSTA',
    'PADRE CICERO',
    'PREF. JOSÉ MARINHO FILHO',
    'PRINCESA ISABEL',
    'PROJETADA I',
    'RONDINELLE GUIMARÃES COUTO',
    'RUA DO AGRICULTOR',
    'SEBASTIÃO CORDEIRO DA COSTA',
    'SEBASTIÃO VICTOR',
    'SEVERINO ANTONIO DA COSTA',
    'SEVERINO JOAQUIM DE OLIVEIRA',
    'SEVERINO TOMAZ DE ASSIS',
    'TRAVESSA JOSE BORGES',
    'VANDERLAN ALVES DE SOUZA'
];
